import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { IJobOffer } from '../components/IJobOffer';
import { JobOffer } from '../components/JobOffer';
import { Helmet } from 'react-helmet-async';
import { Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const hemletData = {
    title: "Spenande - Careers",
    description: "We are hiring! Please check out current jobs postings and apply."
}

export default function Careers() {
    const theme = useTheme();
    const [offers, setOffers] = useState<IJobOffer[]>([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getOffers();
    }, []);

    async function getOffers() {
        try {
            setIsLoading(true);
            const response = await fetch('/api/jobs');
            const payload = await response.json();

            if (payload) {
                setOffers(payload);
            }
        } catch (error: any) {
            setError('Error when fetching job offers. ' + error?.message?.toString());
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{hemletData.title}</title>
                <link rel="canonical" href="/careers"></link>
                <meta name="description" content={hemletData.description}></meta>
                <meta property="og:title" content={hemletData.title} />
                <meta property="og:url" content="/careers" />
                <meta property="og:description" content={hemletData.description} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem' }}>
                    <Typography variant="h6">
                        We are constantly looking for new colleagues to join our team. Please check out current jobs postings and apply!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box maxWidth="md" textAlign='center'>
                        {error.length > 0 && <Alert severity="error">{error}</Alert>}
                        {isLoading && <CircularProgress sx={{ my: '10vh', ml: 'auto', mr: 'auto' }} />}
                    </Box>
                </Grid>
                {offers && offers.map((offer) => (
                    <Grid item xs={12} sx={{ padding: '1rem' }} key={offer.rowKey}>
                        <JobOffer offer={offer} mode='compact'></JobOffer>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
