import { useState, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Consulting from './pages/Consulting';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import JobEdit from './pages/JobEdit';
import JobList from './pages/JobList';
import JobApply from './pages/JobApply';
import ContactMessageList from './pages/ContactMessageList';
import TermsOfService from './pages/TermsOfService';
import CssBaseline from '@mui/material/CssBaseline';
import ScrollToTop from "./utils/ScrollToTop";
import PrivacyPolicy from './pages/PrivacyPolicy';
import Gdpr from './pages/Gdpr';
import Unauthorized from './pages/Unauthorized';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff3d00',
    },
    background: {
      paper: '#1d1d1d'
    }
  },
  typography: {
    fontFamily: 'Poppins',
  }
});

let theme = responsiveFontSizes(darkTheme);

function App() {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;

      if (clientPrincipal) {
        setUser(clientPrincipal);
        //console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
      }
    } catch (error: any) {
      console.error('No profile could be found ' + error?.message?.toString());
    }
  };

  function isAdmin() {
    if (user === null) return false;

    return user?.userRoles.includes("administrator");
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Header user={user} />
        <Routes>
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/apply/:id" element={<JobApply />} />
          <Route path="/admin/joblist" element={isAdmin() ? <JobList /> : <Unauthorized />} />
          <Route path="/admin/jobedit/add" element={isAdmin() ? <JobEdit /> : <Unauthorized />} />
          <Route path="/admin/jobedit/:id" element={isAdmin() ? <JobEdit /> : <Unauthorized />} />
          <Route path="/admin/messages" element={isAdmin() ? <ContactMessageList /> : <Unauthorized />} />
          <Route path="/" element={<Consulting />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
