import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { Alert, Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Inputs {
    email: string;
    message: string;
    acceptTerms: boolean;
    acceptCVProc: boolean;
}

export interface IApplyFormProps {
    id: string;
}

export default function ApplyForm(props: IApplyFormProps) {
    const [submitted, setSumbitted] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        maxFiles: 2,
        maxSize: 5242880
    });

    const files = acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            <li key={file.name}>
                {file.name} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </li>
        )
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please provide an Email address')
            .max(200, 'Email must not exceed 200 characters')
            .email('Email address is invalid'),
        message: Yup.string()
            .required('Please type your message')
            .max(1000, 'Message must not exceed 1000 characters'),
        acceptCVProc: Yup.bool().oneOf([true], 'Please authorize personal data processing'),
        acceptTerms: Yup.bool().oneOf([true], 'Please accept terms of service and privacy policy')
    });

    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: Inputs) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('email', data.email);
            formData.append('message', data.message);
            formData.append('acceptCVProc', String(data.acceptCVProc));
            formData.append('acceptTerms', String(data.acceptTerms));
            for (let i = 0; i < acceptedFiles.length; i++) {
                formData.append('file' + i, acceptedFiles[i]);
            }

            var res = await fetch('/api/jobs/apply/' + props.id, {
                method: 'POST',
                body: formData
            });

            if (res.status === 201) {
                setError("");
                setSumbitted(true);
            }
            else {
                setError("Something went wrong. Please try again later.")
            }
        } catch (error: any) {
            setError('Error when sending request. ' + error?.message?.toString());
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {(!submitted || error.length > 0) && <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} maxWidth="sm">
                    <Grid item xs={12} sx={{ textAlign: 'center' }} >
                        <Typography variant="button" fontSize="large" textAlign="center" color="primary">
                            Apply
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="email"
                            label="Email address"
                            variant="filled"
                            fullWidth
                            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="message"
                            label="Message"
                            multiline
                            fullWidth
                            rows={5}
                            variant="filled"
                            {...register('message', { required: true })}
                            error={errors.message ? true : false}
                            helperText={errors.message?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box {...getRootProps({})} sx={{ borderWidth: '2px', borderStyle: 'dashed', p: '1rem' }} >
                            <input {...getInputProps()} />
                            <p>Drag &amp; drop CV here, or click to select file (max. 2, max. 5MB)</p>
                        </Box>
                        {acceptedFiles.length > 0 ?
                            <aside>
                                <h4>Accepted files:</h4>
                                <ul>{files}</ul>
                            </aside>
                            : ''
                        }

                        {fileRejections.length > 0 ?
                            <aside>
                                <h4>Rejected files:</h4>
                                <ul>{fileRejectionItems}</ul>
                            </aside>
                            : ''
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox {...register('acceptCVProc')} id="acceptCVProc" inputProps={{ 'aria-label': 'Accept CV data processing policy' }} />
                        I authorize the processing of my personal data.

                        <p style={{ fontSize: '14px' }}>I voluntarily authorize the processing of my personal data contained in my curriculum vitae and other application documents in connection with this and other recruitment processes conducted by Spenande sp. z o.o., ul. Zabawa 28/LU1, 30-653 Kraków. This authorization may be revoked at any time.</p>
                        <p style={{ fontSize: '10px' }}>Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych zawartych w CV i innych dokumentach aplikacyjnych w celu związanym w udziałem w niniejszym procesie rekrutacyjnym oraz w innych procesach rekrutacyjnych prowadzonych przez Spenande sp. z o.o., ul. Zabawa 28/LU1, 30-653 Kraków. Powyższa zgoda może zostać cofnięta w dowolnym momencie.</p>
                        <FormHelperText error={errors.acceptCVProc ? true : false}>{errors.acceptCVProc?.message}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox {...register('acceptTerms')} id="acceptTerms" inputProps={{ 'aria-label': 'Accept terms of service and privacy policy' }} />
                        <span>I accept <Link to='/terms' style={{ cursor: 'pointer', color: theme.palette.primary.main }}>Terms of service</Link>&nbsp;
                            and <Link to='/privacy' style={{ cursor: 'pointer', color: theme.palette.primary.main }}>Privacy policy</Link>.</span>
                        <FormHelperText error={errors.acceptTerms ? true : false}>{errors.acceptTerms?.message}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        {error.length > 0 && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="outlined"
                                type="submit"
                                disabled={isLoading}
                                endIcon={<SendIcon />}
                                sx={{ width: '150px' }}
                            >
                                Apply
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>}
            {error.length === 0 && submitted && <Grid container spacing={3} maxWidth="sm">
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <DoneIcon color="primary" fontSize="large" />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <Typography variant="body1" textAlign="center">
                        Your application has been submitted.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <Button component={Link} to={'/'} variant="outlined" color="primary">Back to homepage</Button>
                </Grid>
            </Grid>}
        </>
    );
}
