import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IContactMessage } from '../components/IContactMessage';

export default function JobList() {
    const theme = useTheme();
    const [messages, setMessages] = useState<IContactMessage[]>([]);
    
    useEffect(() => {
        document.title = "Spenande - Contact messages";
        getMessages();

        return () => {
            setMessages([]);
        }
    }, []);

    async function getMessages() {
        try {
            const response = await fetch('/api/secure/contactmessage');
            const payload = await response.json();

            if (payload) {
                setMessages(payload);
            }
        } catch (error: any) {
            console.error('Error when fetching contact messages ' + error?.message?.toString());
        }
    };

    return (
        <>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12}>
                    {messages && <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Timestamp</TableCell>
                                <TableCell>Email/Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {messages.map((message) => (
                                <TableRow
                                    key={message.rowKey}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {message.timestamp.toString()}
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                        From: <Link to={'mailto:' + message.email} target='_blank' style={{ color: theme.palette.primary.dark }}>{message.email}</Link>
                                        <hr />
                                        {message.message}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                </Grid>
            </Grid>
        </>
    );
}
