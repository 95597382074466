import ContactForm from '../components/ContactForm';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';

export interface IContactProps {
}

const hemletData = {
    title: "Spenande - Contact",
    description: "The contact form used to get in touch with our team."
}

export default function Contact(props: IContactProps) {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>{hemletData.title}</title>
                <link rel="canonical" href="/contact"></link>
                <meta name="description" content={hemletData.description}></meta>
                {/* <meta property="og:image" content="/cons.jpg" /> */}
                <meta property="og:title" content={hemletData.title} />
                <meta property="og:url" content="/contact" />
                <meta property="og:description" content={hemletData.description} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem', pt: '4rem', pb: '4rem', alignItems: 'center', justifyContent: 'center' }}>
                    <Box maxWidth="sm" sx={{ margin: 'auto' }}>
                        <ContactForm />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
