import ApplyForm from '../components/ApplyForm';
import { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { IJobOffer } from '../components/IJobOffer';
import { JobOffer } from '../components/JobOffer';
import { Helmet } from 'react-helmet-async';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const helmetData = {
    title: "Spenande - Apply",
    description: "Job apply form."
}

export default function JobApply() {
    const theme = useTheme();
    let { id } = useParams();
    const [offer, setOffer] = useState<IJobOffer>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");

    const getOffer = useCallback(async () => {
        try {
            const response = await fetch('/api/jobs/' + id);
            const payload = await response.json();

            if (payload) {
                setIsLoading(false);
                setOffer(payload);
            }
        } catch (error: any) {
            setIsLoading(false);
            setError('Error when fetching job offer. ' + error?.message?.toString());
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        getOffer();
        return () => {
            setOffer(undefined);
        }
    }, [getOffer]);

    return (
        <>
            <Helmet>
                <title>{helmetData.title}</title>
                <link rel="canonical" href="/apply"></link>
                <meta name="description" content={helmetData.description}></meta>
                <meta property="og:title" content={helmetData.title} />
                <meta property="og:url" content="/apply" />
                <meta property="og:description" content={helmetData.description} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                    <Box maxWidth="md" textAlign='center'>
                        {error.length > 0 && <Alert severity="error">{error}</Alert>}
                        {isLoading && <CircularProgress sx={{ my: '10vh', ml: 'auto', mr: 'auto' }} />}
                    </Box>
                    <Box maxWidth="md" sx={{ margin: 'auto' }}>
                        {offer?.rowKey && <JobOffer offer={offer} mode='full' />}
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ padding: '1rem', pt: '2rem', pb: '4rem', alignItems: 'center', justifyContent: 'center' }}>
                    <Box maxWidth="sm" sx={{ margin: 'auto' }}>
                        {offer?.rowKey && <ApplyForm id={offer.rowKey} />}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
