import React from 'react'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import { IJobOffer } from './IJobOffer';
import Divider from '@mui/material/Divider';

export interface IJobOfferProps {
    offer: IJobOffer,
    mode: "full" | "compact"
}

export function JobOffer(props: IJobOfferProps) {
    return (
        <Card>
            <CardMedia
                component="img"
                height="120px"
                width="120px"
                image={"/img/" + props.offer.technology + ".svg"}
                alt="Technology"
                sx={{ objectFit: "fill", m: "5px" }}
            />
            <CardContent>
                <Typography variant="button" sx={{ position: 'absolute', mt: '-120px' }}>
                    {props.offer.location}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" sx={{ mb: '0rem' }}>
                    {props.offer.title}
                </Typography>
                <Typography gutterBottom variant="caption" component="div">
                    {props.offer.salaryMin} - {props.offer.salaryMax} {props.offer.currency}
                </Typography>
                {props.offer.tags?.split(",").map((r) => (
                    <Chip key={r} label={r} size="small" sx={{ mr: '4px', mt: '4px' }} />
                ))}
                <Typography variant="body2" color="text.secondary" sx={{my:'1rem'}}>
                    {props.offer.shortDescription}
                </Typography>
                {props.mode === "full" ? <Divider sx={{m: '1rem'}}/> : ''}
                {props.mode === "full" ? <Typography variant="body2" color="text.secondary" sx={{whiteSpace: 'pre-wrap'}}>
                    {props.offer.description}
                </Typography> : ''}
            </CardContent>
            {props.mode === "compact" ? <CardActions>
                <Button component={Link} to={"/apply/" + props.offer.rowKey}>Apply</Button>
            </CardActions> : ''}
        </Card>
    );
}
