import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Unauthorized = () => {
    const theme = useTheme();

    useEffect(() => {
        document.title = "Spenande - Unauthorized";
    }, []);

    return (
        <>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '50px !important', textAlign: 'center', height: '60vh' }}>
                    <Typography variant="h4" sx={{mb: '30px'}}>Unauthorized.</Typography>
                    <Button component={Link} to={'/'} variant="outlined" color="primary">Back to homepage</Button>
                </Grid>
            </Grid>
        </>
    );
};

export default Unauthorized;